import React from "react"
import SEO from "../components/Seo"
import Nav from "../components/Nav"
import ppl from "../images/2n5457_pile.jpg"

const AboutPage = () => (
  <div>
    <SEO title="About" />
    <Nav />
    <div className = "bio-container">
      <img className="bio-image" src={ppl} alt='' />
      <div className="containy">
        <h1 className="name">Hi I'm Mark</h1> 
        <h1 className="short-bio">I'm a web developer by day and tired, confused, aspiring, DIY pedal-maker by night</h1> 
      </div>
    </div>
    
    <div className="biography-container">
      <p></p>
    </div>
  </div>
)

export default AboutPage
